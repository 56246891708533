<template>
  <div class="game">
    <div class="banner-box">
      <div class="banner"><img :src="windowWidth>992?banner.pcBanner:banner.mbBanner" alt=""></div>
    </div>
    <div class="content">
      <div class="content-item" v-for="(item,index) in gameList" :key="index">
        <div class="content-item-left">
          <img :src="item.img" alt="">
        </div>
        <div class="content-item-right">
          <div class="content-item-right-top">
            <div class="line"></div>
            <div class="text">
              <div class="text1">{{item.title}}</div>
              <div class="text12">类型：{{item.variety}}</div>
            </div>
          </div>
          <div class="content-item-right-bottom">
            {{item.introduce}}
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
      windowWidth: document.documentElement.clientWidth,
      banner: {
        pcBanner: require('@/assets/lunbo/qixia.png'),
        mbBanner: require('@/assets/lunbo/m_qixia.png')
      },
      gameList: [
        // {
        //   img: require('@/assets/gameList/zngjs.png'),
        //   title: '最牛弓箭手',
        //   variety: '休闲',
        //   introduce: '《最牛弓箭手》是一款打发时间的休闲小游戏,在这款游戏中,你并不需要让你的箭矢射中靶心,而是让它从靶沿的各个方向贯穿靶身。'
        // },
        // {
        //   img: require('@/assets/gameList/yyxfc.png'),
        //   title: '越野小飞车',
        //   variety: '休闲',
        //   introduce: '蜿蜒曲折的赛道，复杂多变的路况，大脑每一个瞬间的判断都有可能决定下一瞬间的生死。越野小飞车带你领略山地赛车的惊险刺激！'
        // },
        // {
        //   img: require('@/assets/gameList/jumper.png'),
        //   title: '跳跳侠',
        //   variety: '休闲',
        //   introduce: '极限速降，做跳跃的大侠！像素冒险游戏《跳跳侠》闪亮登场！马里奥+射击的组合你见过吗？不可描述的可燃气体爆炸助推了解一下？像素世界、勇敢冒险，多种技能、随心组合，一跳到底、探索未知，打开新世界的大门，惊险刺激的冒险之旅！'
        // },
        // {
        //   img: require('@/assets/gameList/aircraft_risk.png'),
        //   title: '小飞机闯天关',
        //   variety: '休闲',
        //   introduce: '《小飞机闯天关》是一款趣味十足的创意游戏，游戏的画风就像儿童画的涂鸦作品，游戏玩法就是需要不断的在空中飞行，不断躲避来袭。虽然很简单，但是游戏性十足，非常耐玩。童趣画风，经典回忆，穿越障碍，冲向高空吧！冲刺，射击，一气呵成!'
        // },
        // {
        //   img: require('@/assets/gameList/running_pig.png'),
        //   title: '猪！快跑',
        //   variety: '休闲',
        //   introduce: '《猪！快跑》是一款休闲益智的独立游戏，玩家需要控制一直可爱的小猪越过各种障碍，记住每个障碍中门的位置，并拯救自己的“猪”队友来进行游戏，紧张刺激，一起来玩吧。 Q萌画风，简单愉悦,多样玩法，乐趣无穷,多样地图，丰富玩法,拯救“猪”队友。童趣跑酷，拒绝社会摇。！'
        // },
        // {
        //   img: require('@/assets/gameList/gemstone.png'),
        //   title: '宝石必须亮',
        //   variety: '休闲',
        //   introduce: '《宝石必须亮》是一款极度烧脑的益智手游。玩家需要在蜿蜒曲折的宝石迷阵中，通过点击宝石影响周围宝石的形式，达到让所有宝石同时亮起的效果。听起来很简单对不对？可你的IQ够用吗？不服？来《宝石必须亮》挑战一番吧！体验一场全新的脑力风暴创意休闲，轻松娱乐。'
        // },
        // {
        //   img: require('@/assets/gameList/throw_snowballs.png'),
        //   title: '全民打雪仗',
        //   variety: '休闲',
        //   introduce: '什么鬼，水果全都开始作妖了，说好的建国后不许成精呢？还有大夏天哪来的雪啊！！拿起雪球枪，在《全民打雪仗》跟水果们在炎炎夏日里来一场凉爽的雪球大战吧！'
        // },
        // {
        //   img: require('@/assets/gameList/tank.png'),
        //   title: '小坦克大作战',
        //   variety: '休闲',
        //   introduce: '还记得当年红白机上风靡一时的坦克大战么？不论是windows mac还是安卓上都有它复刻的身影。但是时过境迁，坦克大战的游戏画面玩法已经落后于时代。那就来试试《小坦克大作战》吧！亲临炮火硝烟的钢铁战场，驾驶自己亲手改装的战车，投身新一代的坦克大战——小坦克大作战！'
        // },
        // {
        //   img: require('@/assets/gameList/game_dapao2016.jpg'),
        //   title: '打泡救公主',
        //   variety: '休闲益智',
        //   introduce: '《打泡救公主》是一个有趣的和令人上瘾的泡泡射击游戏。瞄准要射击的位置发射泡泡，射击组成3个或更多同颜色的泡泡使其破裂，打破泡泡获得积分。把邪恶的泡泡射下来，拯救最亲爱的公主！当你清除了所有泡泡魔障，公主就得救啦！'
        // },
        // {
        //   img: require('@/assets/gameList/p_atl.jpg'),
        //   title: '梦幻滑雪',
        //   variety: '休闲',
        //   introduce: '《梦幻滑雪》是一款全民滑雪冒险跑酷手游，躲避大树、冰屋等障碍物。今夏不可错过的清凉降温好玩伴！比神庙逃亡更易上手、比地铁跑酷更加刺激！梦幻的滑雪人物，清凉的雪地场景，丰富的道具障碍，简单的操作体验，分成简单、困难、地域三种滑雪模式，和全球玩家一起比试高分。奥运来袭，何不一起运动起来！'
        // },
        // {
        //   img: require('@/assets/gameList/zdjoys_xgs.jpg'),
        //   title: '糖果小怪兽',
        //   variety: '休闲',
        //   introduce: '《糖果小怪兽与》是一款突破性的物理碰撞游戏，使用了经典物理原理，设置了一系列设计精巧的关卡。你将操作小怪兽进行大作战，通过不断撞击撕掉包裹糖果的糖纸，帮助小怪兽顺利吃到糖果。玩法简单却又不失趣味，一起来感受完美的物理碰撞体验吧！'
        // },
        // {
        //   img: require('@/assets/gameList/game_mxxc.jpg'),
        //   title: '魔性消除',
        //   variety: '休闲益智',
        //   introduce: '《魔性消除》是一款让人完全停不下来的游戏，六边形消除，有点像俄罗斯方块，系统一次出3个方块，我们在六边形的界面当中，把这些方块放进去，当一个边是同一个颜色时，就消除，规则简单，但很有魔性，让人停不下来。'
        // },
        // {
        //   img: require('@/assets/gameList/game_cdlk.jpg'),
        //   title: '吃掉绿块',
        //   variety: '休闲',
        //   introduce: '《吃掉绿块》是一款消除类闯关的游戏，游戏中控制黄色方块吃掉绿色方块，还要躲开红色的方块。'
        // },
        {
          img: require('@/assets/gameList/01.png'),
          title: '幻象忍者',
          variety: '休闲',
          introduce: '《幻象忍者》是一款闯关游戏。在游戏中玩家需要操作主角，想法设法的让他跳过关卡中的一个个障碍和陷阱，游戏中会出现一些难以通过的障碍，这个时候就需要利用一些有趣的特殊道具，利用玩家灵活的操作来通过关卡。在游戏中通过的关卡越多，玩家在排行榜中的排名就越高，在众多玩家中彰显自身的实力。'
        },
        {
          img: require('@/assets/gameList/02.png'),
          title: '开心打泡泡',
          variety: '休闲',
          introduce: '《开心打泡泡》是一款制作精良的消除类休闲游戏。一位在泡泡世界自由自在的姜饼人，为了拯救泡泡世界的危机，在父亲的指导下，寻找各种各样的宝物，而不断的在泡泡世界游历和锻炼。'
        },
        {
          img: require('@/assets/gameList/03.png'),
          title: '奔跑吧原始人',
          variety: '休闲',
          introduce: '在原始大陆，生活着一对夫妻。有一天，原始人妻子在小池塘开心的洗澡，将衣服留在岸边。万万没想到，草丛中有一对贼兮兮的眼睛正在盯着她留在岸上的衣服——原来是一只喜欢恶作剧的猴子。'
        },
        // {
        //   img: require('@/assets/gameList/04.png'),
        //   title: '掌动聊斋OL',
        //   variety: '休闲',
        //   introduce: '《聊斋online》下是一款中国玄幻风格的Q版即时制MMORPG，画面精美场景写意。游戏以脍炙人口的聊斋故事玩家可以在游戏中能看到熟悉的宁采臣和聂小倩，体验崂山道士神奇的法术，感受捉鬼杀妖的快感，仗剑江湖的侠义，还可以与好友爱侣一同共游奇幻世界，徜徉与山水之间，享受无尽美景！'
        // },
        {
          img: require('@/assets/gameList/05.png'),
          title: '别找茬',
          variety: '休闲',
          introduce: '《别找茬》是一款刺激有趣的找茬游戏。乱糟糟的房间，各种急需的东西夹杂在一堆物品当中，需要帮着急的小妹妹把东西找出来！看看你能找到多少,游戏有限定时间,考验你眼力的时候到了,而且加入了闯关模式，丰富关卡待你探索，还等什么,一起来挑战吧。'
        },
        {
          img: require('@/assets/gameList/06.png'),
          title: '别吃鸡',
          variety: '休闲',
          introduce: '《别吃鸡》是一款休闲小游戏，游戏中玩家会遇到各种不同的敌人，需要搭配不一样的武器，才能更快的将敌人收服，通过别吃鸡全武器合成攻略图一览可以了解每个武器的合成进攻，根据自己手中所拥有的武器合成强大的武器来对抗一波又一波的敌人。'
        },
        // {
        //   img: require('@/assets/gameList/07.png'),
        //   title: '笑傲天龙',
        //   variety: '休闲',
        //   introduce: '《笑傲天龙》是一款仙侠类手机客户端大型网络(MMORPG)游戏。游戏拥有一系列独创特色玩法，其中妙趣横生的奇幻世界，唯美怡人的仙林奇境，带给玩家的不仅是新鲜感，更是能长期陪伴玩家，让玩家突破自我，传递正能量同时也能防止玩家沉迷而采取离线挂机模式的一款仙侠手机网络游戏。'
        // },
        {
          img: require('@/assets/gameList/08.png'),
          title: '掌动仙魔决',
          variety: '休闲',
          introduce: '《仙魔决》是一款新派超写实风格3D国创仙侠手游，全3D打造仙城蓬莱、四大悬浮仙岛，一山一木均精雕细琢，致力还原上古神话中两仪二圣、四凶四灵等数十种猛兽，皆可追寻猎杀夺取宝物，更有悟道、天命、道德等原汁原味的修行玩法，为你带来更有仙味的修仙体验。'
        },
        {
          img: require('@/assets/gameList/09.png'),
          title: '开心点点消',
          variety: '休闲',
          introduce: '开心点点消是一款休闲益智的点击消除类小游戏。只需点击两个或两个以上连在一起的同色方块，就能将其消除，消除越多，得分越多，分数可以兑换多种多样的道具，帮助你更快通过关卡。'
        },
        {
          img: require('@/assets/gameList/10.png'),
          title: '菜鸟小忍者',
          variety: '休闲',
          introduce: '《菜鸟小忍者》是一个roguelike自走棋游戏，你将扮演一个初出茅庐的小菜鸟，义无反顾地走进刀光剑影的江湖，不断与各路高手一决高下。这里有多种门派、技能、天赋、职业，只需一根手指即可轻松畅玩，在几分钟的时间里享受割草秒人的快感，每个技能升到满级还能提供炫酷特效,护肝不氪，搭配各种流派轻松通关无压力!'
        }
        // {
        //   img: require('@/assets/gameList/11.png'),
        //   title: '口袋宠物世界',
        //   variety: '休闲',
        //   introduce: '《口袋宠物世界》是一款以社交、养宠、婚育为核心的宠物婚恋类SNS卡牌手游，游戏在传统卡牌闯关玩法的基础上，创新加入了主角天赋与宠物装备系统，搭配多种阵型让战斗场面更精彩。此外，强大的社交系统让你随时搜寻身边的萌宠小伙伴，打造超萌朋友圈。'
        // }
      ]
    }
  },
  components: {

  },
  created () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
  .game {
    padding-top: 50px;
    background-color: #f4f2f2;

    .banner-box {
      display: flex;
      justify-content: center;
      .banner {
        width: 1400px;
        // width: 100%;
        height: 400px;
        // margin: 0 auto;
        // background-color: aqua;
        margin-bottom: 80px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }

    .content {
      // .content-item:last-child(){
      //   margin-bottom: 30px;

      // }
      padding-bottom: 80px;
      .content-item {
        width: 1002px;
        margin: 0 auto;
        display: flex;
        background-color: #fff;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }

        .content-item-left {
          width: 244px;
          height: 238px;
          // background-color: blue;
          padding: 10px;
          img {
            width: 100%;
            height: 100%;
          }
          border-right: 1px solid #e7e7e7;
        }
        .content-item-right {
          padding: 20px 30px;
          width: 734px;
          height: 238px;
          // background-color: red;
          .content-item-right-top {
            height: 42px;
            // background-color: aquamarine;
            display: flex;
            align-items: center;
            .line {
              width: 3px;
              height: 38px;
              background-color: #f08200;
              margin-right: 10px;
            }
            .text {
              .text1 {
                font-size: 18px;
                color: #f08200;
              }
              .text12 {
                font-family: '宋体';
                color: #6b6c6f;
              }
            }
          }
          .content-item-right-bottom {
            width: 415px;
            margin-top: 20px;
            text-indent: 2em;
            color: #909195;
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .game {
    padding-top: 50px;
    background-color: #f4f2f2;

    .banner {
      // display: none;
      height: 150px;
      width: 100%;
      // background-color: aqua;
      // margin-bottom:40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content {
      padding: 20px 0;
      // margin-top: 20px;
      // padding-bottom: 20px;
      .content-item {
        width: 360px;
        margin: 0 auto;
        display: flex;
        background-color: #fff;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        &:last-child {
          margin-bottom: 0;
        }
        .content-item-left {
          margin: 10px;
          width: 100px;
          height: 100px;
          // background-color: blue;
          // padding: 10px;
          img {
            width: 100%;
            height: 100%;
          }
          // border-right: 1px solid #e7e7e7;
        }
        .content-item-right {
          padding: 0 5px;
          width: 200px;
          height: 150px;
          // background-color: red;
          .content-item-right-top {
            // height: 20px;
            // background-color: aquamarine;
            display: flex;
            align-items: center;
            .line {
              width: 3px;
              height: 30px;
              background-color: #f08200;
              margin-right: 5px;
            }
            .text {
              .text1 {
                font-size: 14px;
                color: #f08200;
              }
              .text12 {
                font-family: '宋体';
                color: #6b6c6f;
                font-size: 12px;
              }
            }
          }
          .content-item-right-bottom {
            width: 260px;
            // margin-top: 20px;
            text-indent: 2em;
            color: #909195;
            font-size: 10px;
            transform: scale(0.83333);
            transform-origin: 0 0;
          }
        }
      }
    }
  }
}
</style>
